import * as React from "react"

import {
  Button,
  ButtonVariantEnum,
  classNames,
} from "@behaviour-lab/blab-component-library/components"
import XMarkIcon from "@behaviour-lab/blab-component-library/icons/common/XMarkIcon"
import { Dialog, Transition } from "@headlessui/react"

interface ISidePanelProps {
  opened: boolean
  title: string
  description?: string
  children: React.ReactNode
  onClose: () => void
  className?: string
}

const SidePanel = ({
  opened,
  onClose,
  title,
  description,
  children,
  className,
}: ISidePanelProps) => {
  return (
    <Transition.Root show={opened} as={React.Fragment}>
      <Dialog as="div" className="relative z-[70]" onClose={onClose}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden" data-testid="side-panel">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
              <Transition.Child
                as={React.Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="w-screen max-w-md pointer-events-auto">
                  <div className="relative flex flex-col h-full overflow-y-auto border-l shadow-md bg-olive-100 border-oliveA-700">
                    <div className="sticky top-0 z-50 p-6 border-b bg-olive-100 border-oliveA-700">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-xl font-medium">
                          {title}
                        </Dialog.Title>
                        <div className="flex items-center ml-3 h-7">
                          <Button
                            onClick={onClose}
                            variant={ButtonVariantEnum.Transparent}
                            className="justify-center px-0 py-0"
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon
                              className="w-6 h-6"
                              aria-hidden="true"
                              data-testid="close-side-panel"
                            />
                          </Button>
                        </div>
                      </div>
                      {!!description && (
                        <Dialog.Description className="text-olive-1000 text-md">
                          {description}
                        </Dialog.Description>
                      )}
                    </div>
                    <div className={classNames("p-6", className)}>
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default SidePanel
